<template>
	<div>
		<img src="../../../assets/imges/index/4.png" class="headerimg" />
		<div class="dynamic">

			<div class="dynamicheader">
				<span class="title1">集团动态</span><span class="arrows">></span>
				<span :class="[{ title2hover: headerid == item.id ? 'title2hover' : '' },'title2',]" v-for="(item,index) in GroupDynamicTypeList" :key='index'
				 @click="getgetGroupDynamicList(item.id)">{{item.name}}</span>
			</div>
			<div class="dynamicconter">
				<el-timeline class="dynamtimeline">
					<el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" size="large"
					 :hide-timestamp="true" placement="top" @click.native="timegetlist(activity.yearInfo)" :color="time == activity.yearInfo ? '#2F7FFC' : ''">
						<div class="timeline" :style="{ color: time == activity.yearInfo ? '#2F7FFC' : '' }">
							{{ activity.yearInfo }}
						</div>
					</el-timeline-item>
				</el-timeline>

				<div class="list">
					<div class="card" v-for="(item, index) in dynamicinfolist" :key="index">
						<div class="listtime">
							<div class="year">{{ item.year }}</div>
							<div class="time">{{ item.time }}</div>
						</div>
						<div class="listinfo">
							<div class="infotitle">{{ item.title }}</div>
							<div class="infotext">
								{{ item.brief }}
							</div>
							<div class="todetail" @click="toDeail(item)">查看详情</div>
						</div>
					</div>
					<Pagination :pagesize="pagesize" :total="total" @chang="handleSizeChange"></Pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from "../../../components/Pagination";
	import {
		GroupDynamicTypeList,
		GroupDynamicFrontendQuery,GroupDynamicYearList
	} from '../../../api/index.js'
	import {
		daytim,yearmonth
	} from '../../../utils/util.js'
	export default {
		components: {
			Pagination,
		},
		data() {
			return {
				total: 100,
				pagesize: 5,
				PageNo:1,
				headerid: "1", // 列表类型
				time: "2019", //选中的时间
				activities: [{
						timestamp: "2020",
					},
					{
						timestamp: "2019",
					},
					{
						timestamp: "2018",
					},
					{
						timestamp: "2017",
					},
				],
				GroupDynamicTypeList:[],
				dynamicinfolist: [{
						time: "2020-12",
						year: "12",
						title: "热烈祝贺公司与业内知名品牌达成合作",
						info: "Callbaby APP是一款面向全民的智能生活管家。产品支持智能呼叫、招聘兼职、共享社区、校园资讯、知识海洋等功能一款面向全民的智能生活管家。产品支持智能呼叫、招聘兼职、共享社区、校园资讯、知识海洋等一款面向全民的智能生活管家。产品支持智能呼叫、招聘兼职、共享社区、校园资讯、知识海洋等。为保证服务质量…",
					},
					{
						time: "2020-12",
						year: "12",
						title: "热烈祝贺公司与业内知名品牌达成合作",
						info: "Callbaby APP是一款面向全民的智能生活管家。产品支持智能呼叫、招聘兼职、共享社区、校园资讯、知识海洋等功能。为保证服务质量…",
					},
					{
						time: "2020-12",
						year: "12",
						title: "热烈祝贺公司与业内知名品牌达成合作",
						info: "Callbaby APP是一款面向全民的智能生活管家。产品支持智能呼叫、招聘兼职、共享社区、校园资讯、知识海洋等功能。为保证服务质量…",
					},
				],
			};
		},
		mounted() {
			GroupDynamicTypeList().then(res => {
				this.GroupDynamicTypeList = res.data.splice(1, res.data.length - 1)
				GroupDynamicYearList().then(res => {
					this.activities = res.data
					this.time = this.activities[0].yearInfo
					if(this.$route.query.id){
						this.getgetGroupDynamicList(this.$route.query.id)
					} else {
						this.getgetGroupDynamicList(this.GroupDynamicTypeList[0].id)
					}
				})
			})
		},
		methods: {
			getgetGroupDynamicList(item) {
				this.headerid = item
				GroupDynamicFrontendQuery({
					DynamicTypeId: this.headerid,
					PageNo: this.PageNo,
					YearInfo:this.time,
					PageSize: this.pagesize
				}).then(res => {
					this.total = res.data.totalCount
					if(res.data.items.length >0){
						let dynamicinfolist = res.data.items
						dynamicinfolist.forEach(function(item, index) {
							let time = item.releaseTime.split(' ')[0]
							item.time = yearmonth(new Date(time))
							item.year = daytim(new Date(time))
						})
						this.dynamicinfolist = dynamicinfolist
					} else {
						this.dynamicinfo ={}
						this.dynamicinfolist =[]
					}
					
				})
			},
			timegetlist(time) {
				this.time = time;
				this.PageNo = 1
				this.getgetGroupDynamicList(this.headerid)
			},
			getlist(typeid) {
				this.headerid = typeid;
			},
			handleSizeChange(val) {
				this.PageNo = val
				this.getgetGroupDynamicList(this.headerid)
			},
			// 查看详情
			toDeail(item) {
				this.$router.push({path:'/ExhibitionDetails',query:{id:item.id}})
			}
		},
	};
</script>

<style scoped lang="less">
	.dynamicconter /deep/.el-timeline-item__node--large {
		width: 18px;
		height: 18px;
	}

	.dynamicconter /deep/.el-timeline-item__tail {
		margin-left: 2px;
	}

	.dynamic {
		width: 80%;
		margin: 0 auto;
		padding-bottom: 30px;

		.dynamicheader {
			padding: 59px 0;
			font-size: 28px;
			border-bottom: 1px solid #979797;

			.title1 {
				font-family: SourceHanSansCN-Bold, SourceHanSansCN;
				font-weight: bold;
			}

			.arrows {
				color: #bebebe;
				padding-left: 25px;
			}

			.title2 {
				font-weight: 500;
				margin-left: 97px;
				color: #b4b4b4;
				cursor: pointer;
			}

			.title2hover {
				color: #2f7ffc;
				padding-bottom: 15px;
				border-bottom: 6px solid #2f7ffc;
			}
		}

		.dynamicconter {
			margin-top: 70px;
			display: flex;

			.dynamtimeline {
				margin-top: 33px;

				.timeline {
					height: 71px;
					font-size: 24px;
					font-weight: 500;
					color: #b4b4b4;
					cursor: pointer;
				}
			}

			.list {
				padding-left: 145px;
				border-left: 1px solid #979797;
				margin-left: 126px;

				.card:hover {
					box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);

					.listtime {
						background-color: #2f7ffc;
						color: #fff;
					}
				}

				.card {
					width: 1150px;
					height: 200px;
					margin-top: 33px;
					// border: 1px solid #e8e8e8;
					border-radius: 17px;
					padding: 31px 36px;
					cursor: pointer;
					display: flex;

					.listtime {
						background-color: #f9f9f9;
						text-align: center;
						padding: 0 39px;
						padding-top: 41px;
						width: 159px;
						border-radius: 7px;

						.year {
							font-size: 50px;
							font-weight: bold;
							line-height: 75px;
						}

						.time {
							// margin-top: 18px;
							font-size: 32px;
							font-weight: 500;
						}
					}

					.listinfo {
						margin-left: 36px;

						.infotitle {
							font-size: 28px;
							font-family: PingFangSC-Semibold, PingFang SC;
							font-weight: 600;
							margin-top: 19px;
						}

						.infotext {
							height: 56px;
							font-size: 18px;
							font-family: SourceHanSansSC-Medium, SourceHanSansSC;
							font-weight: 500;
							color: #a2a3a4;
							line-height: 28px;
							margin-top: 14px;
							width: 851px;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
						}

						.todetail {
							font-size: 18px;
							font-weight: 500;
							color: #2f7ffc;
							line-height: 27px;
							float: right;
							margin-top: 28px;
						}
					}
				}
			}
		}
	}
</style>
